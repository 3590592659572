<template>
  <v-main style="background-color: #fff" class="mt-16">
    <v-container class="mt-16">
      <p class="dtitle">{{ place.name }}</p>
      <v-row class="mt-4">
        <v-col cols="12" md="8" lg="8">
          <v-carousel
            v-if="place"
            cycle
            height="400"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(location, lindex) in place.images"
              :key="lindex"
              :src="location.fileUrl"
            >
            </v-carousel-item>
          </v-carousel>

          <!-- <v-img src="/bg2.jpg"></v-img> -->
        </v-col>

        <v-col cols="12" md="4" lg="4">
          <v-card height="400">
            <v-card-text>
              <Photoswipe style="display: inline-block">
                <img
                  v-for="(location, lindex) in place.images"
                  :key="lindex"
                  :src="location.fileUrl"
                  class="me-2"
                  v-pswp="location.fileUrl"
                  style="display: inline-block"
                  :style="getImageItemStyle(location.fileUrl)"
                />
              </Photoswipe>
            </v-card-text>
            <v-col cols="12" class="mx-auto">
              <v-btn depressed block class="bg-gradient-error" dark>
                Buy a tour
              </v-btn>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row justify="space-between">
        <v-col cols="12" md="8" lg="8">
          <div class="dtext">
            Experience the sweeping landscapes of Mongolia, one of the emptiest
            nations on earth, on this convenient guided day trip, with flexible
            2-way transfers. Start at the majestic Genghis Khan Statue Complex,
            topped with a 131-foot (40-meter) statue that’s one of the tallest
            equestrian statues in the world. Then, explore Gorkhi-Terelj
            National Park: eat lunch at Turtle Rock, hike to Aryabal Meditation
            Temple, and meet a nomadic family in their ger.
          </div>
          <v-row class="mt-4">
            <v-col cols="12" md="6" lg="6">
              <span class="dtext-title font-weight-bold"
                >Know before you go</span
              >
              <p>dfsdf</p>
              <p>dfsdf</p>
              <p>dfsdf</p>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <span class="dtext-title font-weight-bold"
                >Know before you go</span
              >
              <p>dfsdf</p>
              <p>dfsdf</p>
              <p>dfsdf</p>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12">
              <span class="dtext-title font-weight-bold"
                >Know before you go</span
              >
              <p>
                Capture timeless photos of classic Mongolian scenery, close to
                the city
              </p>
              <p>
                Capture timeless photos of classic Mongolian scenery, close to
                the city
              </p>
              <p>
                Capture timeless photos of classic Mongolian scenery, close to
                the city
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <h1 class="dtext-title font-weight-bold">Available languages</h1>
          <p class="dtext">Mongolian, English</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <!-- <Photoswipe>
    <img 
        :src="imageSrc"
        v-pswp="imageSrc"
    />
</Photoswipe> -->
    </v-container>
    <!-- <v-container class="mt-16">
      <v-img src="/bg2.jpg"></v-img>
      <h1 class="mt-16 text-h2">Миний аавын нутаг {{place.name}}</h1>
      <p class="mt-4">
        Persis Drell is Stanford's thirteenth provost. As the chief academic and
        chief budgetary officer of the university, the provost is responsible
        for administering the academic program, including both instruction and
        research, and for
      </p>
    </v-container>
  -->
  </v-main>
</template>

<style>
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}
.btn {
  justify-content: start;
}
@media only screen and (min-width: 1100px) {
  .navbtn {
    visibility: hidden;
  }
}
@media only screen and (max-width: 1100px) {
  .navmenu {
    display: none;
  }
}
.stick {
  position: sticky;
  top: 10%;
}
</style>
<script>
const fb = require("../../firebaseConfig.js");
import Vue from "vue";
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

export default {
  name: "TourDetail",
  components: {},
  data: () => ({
    slides: [
      {
        src: "https://farm4.staticflickr.com/3894/15008518202_c265dfa55f_h.jpg",
      },
      {
        src: "https://farm6.staticflickr.com/5591/15008867125_b61960af01_h.jpg",
      },
    ],
    completeImageList: [
      {
        src: "https://farm4.staticflickr.com/3894/15008518202_c265dfa55f_h.jpg",
        size: "1600x1600",
        msrc: "https://farm4.staticflickr.com/3894/15008518202_b016d7d289_m.jpg",
        title: "this is dummy caption",
      },
      {
        src: "https://farm6.staticflickr.com/5591/15008867125_b61960af01_h.jpg",
        size: "1600x1068",
        msrc: "https://farm6.staticflickr.com/5591/15008867125_68a8ed88cc_m.jpg",
        title: "this is dummy caption",
      },
      {
        src: "https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg",
        size: "1600x1067",
        msrc: "https://farm4.staticflickr.com/3902/14985871946_86abb8c56f_m.jpg",
        title: "this is dummy caption",
      },
    ],
    place: null,
    messages: [
      {
        from: "You",
        message: `Sure, I'll see you later.`,
        time: "10:42am",
        color: "deep-purple lighten-1",
      },
      {
        from: "John Doe",
        message: "Yeah, sure. Does 1:00pm work?",
        time: "10:37am",
        color: "green",
      },
      {
        from: "You",
        message: "Did you still want to grab lunch today?",
        time: "9:47am",
        color: "deep-purple lighten-1",
      },
    ],
    drawer: false,
    group: null,
    items: ["Home", "Pricing", "About Us"],
    items1: [
      "News",
      "Events",
      "Academics",
      "Research",
      "Health Care",
      "Campus Life",
      "Admission",
      "About",
    ],
  }),
  props: {
    itemId: {
      type: String,
      required: true,
    },
  },
  methods: {},
  created() {
    fb.db
      .doc(this.itemId)
      .get()
      .then((doc) => {
        let place = doc.data();
        place.id = doc.id;
        place.ref = doc.ref;
        this.place = place;
      });
  },
  methods: {
    getImageItemStyle(src) {
      return {
        width: "64px",
        height: "64px",
        backgroundImage: `url(${src})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };
    },
  },
};
</script>
<style>
.dtitle {
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
}
.dtext {
  line-break: normal;
  cursor: auto;
  font-size: 16px;
  line-height: 26px;
  color: #585858 !important;
}
.dtext-title {
  line-break: normal;
  cursor: auto;
  font-size: 16px;
  line-height: 26px;
  color: #000 !important;
}
</style>
